import React, { Component } from "react"
import { Container } from "react-bootstrap"

class FSFind extends Component{
    render()
    {
        return(
        <Container>
            FSFind
        </Container>);
    }
}

export default FSFind;
