import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import registerServiceWorker from "./registerServiceWorker";
import App from "./scripts/App";
import { ConnectedRouter } from 'react-router-redux'
import { store, history } from './scripts/store';
import { BrowserRouter } from 'react-router-dom';
ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>,
  document.getElementById("root")
);
//registerServiceWorker();
