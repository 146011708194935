import React, { Component } from "react";
import {Link} from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";

class MainModule extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <Button><Link to={'/Tasks'}>Задачи</Link></Button>
            <Button><Link to='/FS'>Сервисный центр</Link></Button>
            <Button><Link to='/Mf'>Производство</Link></Button>
            <Button><Link to='/IntAccounts'>Внутренние счета</Link></Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MainModule;
