import React, { Component } from "react";
import { Container, Row, Col, Alert, Form, Button } from "react-bootstrap";

class RestoreForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
     return (
      <Container>
      </Container>
    );
  }
}

export default (RestoreForm);
