import React, { Component } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

class ColleaguesTasks extends Component{
    render()
    {
        return(
            <div>
        </div>
        );
    }
};

export default ColleaguesTasks;