import React, { Component } from "react"
import { Breadcrumb, Button, Tabs, Tab, ModalFooter, Card, Spinner } from "react-bootstrap"

import api from "../../Api.js";
import Model from "./Model.js";

class Mf extends Component{
    constructor(props)
    {
        super(props);
        this.state={Models:Array, selected:'no', isLoad:false};

        api('GET', 'mf_models', '')
        .then((result)=>{   
            console.log(result);
            this.setState({Models:result.items, isLoad:true});
        });
    }
    render()
    {
        const {Models, selected, isLoad} = this.state;
        if(isLoad)
            return(
                <div>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                        <Breadcrumb.Item active>Производство</Breadcrumb.Item>
                    </Breadcrumb>
                    <Card className="border-secondary">
                        {selected!='no'?(
                        <div>
                            <Model model={Models[selected-1]} 
                                goBack={()=>this.setState({selected: 'no'})}/>
                         </div>):(
                        <div>
                            <ul>
                                {Models.map((model, key)=>
                                    <li key={key}>
                                        <a href='#' onClick={()=>this.setState({selected: model.id})}>{model.name}</a>
                                    </li>
                                )}
                            </ul>
                        </div>)}
                    </Card>
                </div>)
        else
            return(<Spinner animation="grow" />)
    }
}

export default Mf;