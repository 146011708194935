import React, { Component } from "react"
import {Link} from "react-router-dom";
import { Breadcrumb, Table, Button, Form, Tabs,
    Row, Col, Tab, ModalFooter, Card, Spinner } from "react-bootstrap"

import api from "../../Api.js";
import Select from './../../components/SelectDictionary'

class Summ extends Component{
    constructor(props)
    {
        super(props);
        this.state={edit:false,
            lastSumm:this.props.summ,
        summ:this.props.summ}
    }
    handleChange(e) {
        if(e.target.name==="summ" && e.target.value === 0)
            return 0;

        if(e.target.type=='checkbox')
            this.setState({ [e.target.id]: e.target.checked });
        else
            this.setState({ [e.target.name]: e.target.value });
    }
    render()
    {
        return(<div>
        {this.state.edit?(<div>
            <Form.Control type="number" name="summ" 
                value={this.state.summ} size="sm"
                style={{display:"inline", width:"50%"}}
                onChange={(e)=>{this.handleChange(e)}}/>
            <Button size="sm">Сохранить</Button>
        </div>):(<div onClick={()=>this.setState({edit:true})}>
            {this.state.summ}</div>)}
        </div>);
    }
}

class IntAccount extends Component
{
    constructor(props)
    {
        super(props);
        this.state={isLoad:false,
            Account:{id:this.props.match.params.id},
            history:{},
            id_account:this.props.match.params.id,
            category:0,
            purpose:0,
            comment:'',
            summ:0,
            minus: false,
            thousands: false,
        };
        api('GET', 'int_accounts', 'get-account-by-id',{id:this.state.Account.id})
        .then((account)=>{
            api('GET', 'int_accounts', 'get-history-by-id',{id:this.state.Account.id})
            .then((history)=>{  
               // console.log(history); 
                this.setState({Account:account,
                history:history, isLoad:true});
            });
        });

        this.handleAdd = this.handleAdd.bind(this);
    }

    handleChange(e) {
        if(e.target.name==="summ" && e.target.value === 0)
            return 0;

        if(e.target.type=='checkbox')
            this.setState({ [e.target.id]: e.target.checked });
        else
            this.setState({ [e.target.name]: e.target.value });
    }

    handleAdd()
    {
        let summ = this.state.summ;

        if(this.state.thousands)
            summ*=1000;
        
        if(this.state.minus)
            summ*=-1;

        api('POST', 'int_accounts', 'add-history',{
            id_account:this.state.Account.id,
            category:this.state.category,
            purpose:this.state.purpose,
            comment:this.state.comment,
            summ:summ})
            .then((response)=>{   
 //               console.log(response);
                this.setState({isLoad:false});

                api('GET', 'int_accounts', 'get-account-by-id',{id:this.state.Account.id})
                .then((account)=>{
                    api('GET', 'int_accounts', 'get-history-by-id',{id:this.state.Account.id})
                    .then((history)=>{   
                        this.setState({Account:account,
                        history:history, isLoad:true,
                        //category:0,
                        //purpose:0,
                        comment:'',
                        summ:0});
                    });
                });
            });
    }

    render()
    {
        const{isLoad, Account, history} = this.state;
        return(
            <div>
                <Breadcrumb>
                        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                        <Breadcrumb.Item href="/IntAccounts">Внутренние счета</Breadcrumb.Item>
                        <Breadcrumb.Item active>Счет '{Account.name}'</Breadcrumb.Item>
                </Breadcrumb>   
                <Card className="border-secondary">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <td>
                                    <Button onClick={this.handleAdd}>Добавить</Button>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <Select placeholder="Категория"
                                        dictionary="int_accounts_history_category" 
                                        name="category"
                                        onChange={(e)=>{this.handleChange(e)}}/>
                                </td>
                                <td>
                                    <Select placeholder="Назначение"
                                        dictionary="int_accounts_history_purpose" 
                                        name="purpose"
                                        onChange={(e)=>{this.handleChange(e)}}/>
                                </td>
                                <td>
                                    <Row>
                                        <Col colSpan={2}>
                                            <Form.Control type="number" name="summ" 
                                            value={this.state.summ} size="sm"
                                            onChange={(e)=>{this.handleChange(e)}}/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Check type="checkbox" label="Вычитать"
                                            onChange={(e)=>{this.handleChange(e)}} id="minus"/>
                                        </Col>
                                        <Col>
                                            <Form.Check type="checkbox" label="В тысячах"
                                            onChange={(e)=>{this.handleChange(e)}} id="thousands"/>
                                        </Col>
                                    </Row>
                                </td>
                                <td><Form.Control type="text" name="comment" 
                                    value={this.state.comment} size="sm"
                                    onChange={(e)=>{this.handleChange(e)}}/></td>
                            </tr>
                            <tr>
                                <td colSpan={4}></td>
                                <th>Баланс: {Account.balance}</th>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Дата</th>
                                <th>Пользователь</th>
                                <th>Категория</th>
                                <th>Назначение</th>
                                <th>Сумма</th>
                                <th>Комментарий</th>
                            </tr>
                        </thead>
                        {isLoad&&(
                        <tbody>

                            {
                                history.map((row, key)=>(
                                    <tr key={key}>
                                        <td>{row.date}</td>
                                        <td>{row.id_user}</td>
                                        <td>{row.id_category}</td>
                                        <td>{row.id_purpose}</td>
                                        <td><Summ summ={row.summ}/></td>
                                        <td>{row.comment}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    )}
                    </Table>
                </Card>

            </div>
        );
    }
}

export default IntAccount;