import React, { Component } from "react"
import { connect } from "react-redux"
import {store}  from './../../store';
import { Container, Row, Col, Button, Spinner } from "react-bootstrap"
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components'
import api from './../../Api';
import AddTask from './AddTask'

class Tasks extends Component{
    onDragEnd(result) {
        console.log(result);
        if (!result.destination) {
            return;
          }
      }
      render(){

    return(
        <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
        {(provided, snapshot) => (
            <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {this.props.tasks.map((task, index)=>{
                return(<Draggable draggableId={task.id} key={task.id} index={index}>
                    {(provided, snapshot) => (
                        <div
                        ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      >
                      {task.Title}<Button>Добавить подзадачу</Button><Button>Редактировать</Button><Button>Завершить</Button>
                      </div>
                    )}
                    </Draggable>)
            })}
            </div>
        )}
        </Droppable>
        </DragDropContext>
    );}
}

class MyTasks extends Component{
    
    constructor(props)
    {
        super(props);
        this.state={tasksList:{
        UrgentImportant:Array(),
        NoUrgentImportant:Array(),
        UrgentNoImportant:Array(),
        NoUrgentNoImportant:Array()},
        isLoad:false};
        api('GET','tasks','get-tasks-by-user-id',{userId:props.user.ID})
        .then((result)=>{
            console.log(result);
            result.map((Task)=>{
                console.log(Task);
                switch(Task.Type)
                {
                    case 0://Несрочная и неважная
                        this.setState({tasksList:{...this.state.tasksList,
                             NoUrgentNoImportant: this.state.tasksList.NoUrgentNoImportant.concat(Task)}});
                        break;
                    case 1://Срочная и неважная
                        this.setState({tasksList:{...this.state.tasksList,
                            UrgentNoImportant: this.state.tasksList.UrgentNoImportant.concat(Task)}});
                        break;
                    case 2://Несрочная и важная
                        this.setState({tasksList:{...this.state.tasksList,
                            NoUrgentImportant: this.state.tasksList.NoUrgentImportant.concat(Task)}});
                        break;
                    case 3://Срочная и важная
                        this.setState({tasksList:{...this.state.tasksList,
                            UrgentImportant: this.state.tasksList.UrgentImportant.concat(Task)}});
                        break;
                }
                this.setState({isLoad:true})
            });
        });
    }
    render()
    {
        return(
        <div>
            {this.state.isLoad?(
            <Container>
                <Row>
                    <Col>
                        <AddTask/>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:5, offset:2}}>Срочные</Col>
                    <Col md={5}>Несрочные</Col>
                </Row>
                <Row>
                    <Col md={2}>Важные</Col>
                    <Col md={5}><Tasks tasks={this.state.tasksList.UrgentImportant}/></Col>
                    <Col md={5}><Tasks tasks={this.state.tasksList.NoUrgentImportant}/></Col>
                </Row>
                <Row>
                    <Col md={2}>Неважные</Col>
                    <Col md={5}><Tasks tasks={this.state.tasksList.UrgentNoImportant}/></Col>
                    <Col md={5}><Tasks tasks={this.state.tasksList.NoUrgentNoImportant}/></Col>
                </Row>
            </Container>):(
            <Spinner animation="grow" />
            )}
        </div>
        );
    }
};

const mapStateToProps = store => {
    return {
      user: store.User,
    };
  };

export default connect(
    mapStateToProps
  )(MyTasks);