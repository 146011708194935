import React, { Component } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"

class AddTasks extends Component{
    render()
    {
        return(
            <div>
                <form>
                    Задача:
                    <input type='text'/>
                    Тип задачи:
                    <select>
                        <option value='0'>Не срочная и не важная</option>
                        <option value='1'>Срочная и не важная</option>
                        <option value='2'>Несрочная и важная</option>
                        <option value='3'>Срочная и важная</option>
                    </select>
                    Соисполнители:
                    <Button>Добавить</Button>
                </form>
        </div>
        );
    }
};

export default AddTasks;