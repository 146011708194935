import React, { Component } from "react"

import { Container, Row, Col, Button,
    Breadcrumb, Tab, Tabs  } from "react-bootstrap"
import MyTasks from './MyTasks.js'
import AddTask from './AddTask.js'
import ColleaguesTasks from './ColleaguesTasks.js'

class Tasks extends Component{
    render()
    {
        return(
            <div>
            <Breadcrumb>
                <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                <Breadcrumb.Item active>Задачи</Breadcrumb.Item>
            </Breadcrumb>
            
            <div className="card border-secondary">
                <Tabs fill 
                defaultActiveKey="MyTask"
                id="Tasks-tabs">
                    <Tab eventKey="MyTask" title="Мои задачи">
                        <MyTasks/>
                    </Tab>
                    <Tab eventKey="AddTask" title="Добавить">
                        <AddTask/>
                    </Tab>
                    <Tab eventKey="ColleaguesTasks" title="Задачи коллег">
                        <ColleaguesTasks/>
                    </Tab>
                </Tabs>
            </div>
        </div>
        );
    }
};

export default (Tasks);