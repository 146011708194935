import React, { Component } from "react"
import {Link} from "react-router-dom";
import { Breadcrumb, Table, Button, Tabs, Tab, ModalFooter, Card, Spinner } from "react-bootstrap"

import api from "../../Api.js";

class IntAccounts extends Component
{
    constructor(props)
    {
        super(props);
        this.state={isLoad:false,
            Accounts:{},
        total:0};

        api('GET', 'int_accounts', 'views')
        .then((result)=>{   
            console.log(result);
            let total = 0;
            result.map((res)=>total+=res.balance);
            this.setState({Accounts:result,
                isLoad:true,
            total:total});
        });
    }
    render()
    {
        const{isLoad, Accounts, total} = this.state;
        return(
            <div>
             <Breadcrumb>
                        <Breadcrumb.Item href="/">Главная</Breadcrumb.Item>
                        <Breadcrumb.Item active>Внутренние счета</Breadcrumb.Item>
                </Breadcrumb>   
                <Card className="border-secondary">
                    <Table striped bordered hover size="sm">
                    <thead>
                            <tr>
                                <th>Название</th>
                                <th>Баланс: {total}</th>
                            </tr>
                    </thead>
                    <tbody>

                        {isLoad&&
                        (
                            Accounts.map((account, key)=>{
                                return (
                                    <tr key={key}>
                                        <td><Link to={'/IntAccount/'+account.id}>{account.name}</Link></td>
                                        <td>{account.balance}</td>
                                    </tr>
                                );
                            })
                        )}
                        </tbody>
                    </Table>
                </Card>
            </div>
        );
    }
}

export default IntAccounts;