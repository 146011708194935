import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  withRouter,
} from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "../styles/App.css";
import "../styles/bootstrap.css";

import AuthForm from "./Modules/User/AuthForm";
import RegForm from "./Modules/User/RegForm";
import RestoreForm from "./Modules/User/RestoreForm";

import HeaderBlock from "./HeaderBlock";
import ContentBlock from "./ContentBlock";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="App container">
          {!this.props.token ? (
            <Router>
              <Switch>
                <Route exact path='/' component={AuthForm}/>
                <Route path='/reg' component={RegForm}/>
                <Route path='/restore' component={RestoreForm}/>
            </Switch>
          </Router>
          ) : (
            <div>
              <HeaderBlock />
              <ContentBlock />
            </div>
          )}
        </div>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  return {
    token: store.User.token,
    ownProps
  };
};

export default connect(mapStateToProps)(withRouter(App));


