import React, { Component } from "react"
import { Breadcrumb, Button, Tabs, Tab, ModalFooter,
     Row, Col, Container, Spinner,
     Accordion, Nav, Form } from "react-bootstrap"
import Select from './../../components/SelectDictionary'
import api from "../../Api.js";

class CounterEditor extends Component{
    constructor(props){
        super(props)
        this.state={val:1, error:false}
    }
    changeCount(x)
    {
        const {item, parent} = this.props;

        const val = this.state.val*x;

        if(item.count+val>=1)
        {
            if(this.state.error)
                this.setState({error:false});
            
            if(item.type==='unit')
            {
                api('GET', 'mf_unit', 'change_count_unit',{id_parent:parent.id,
                    parent_type:parent.type,
                    id_unit: item.id, val:val})
                .then((result)=>{   
                    this.props.reload();
                });
            }
            if(item.type==='detail')
            {
                api('GET', 'wh_detail', 'change_count_detail',{id_parent:parent.id,
                    parent_type:parent.type,
                    id_detail:item.id, val:val})
                .then((result)=>{   
                    this.props.reload();                   
                });
            }
        }
        else
        {
            this.setState({error:true});
        }
    }

    del()
    {
        const {item, parent} = this.props;
            
            if(parent.type==='model')
            {
                if(item.type==='unit')
                    api('GET', 'mf_models', 'del_unit',{id_model:parent.id, id_unit: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
                else
                    api('GET', 'mf_models', 'del_detail',{id_model:parent.id, id_detail: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
            }
            else
            {
                if(item.type==='unit')
                    api('GET', 'mf_unit', 'del_unit',{id_parent:parent.id, id_unit: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
                else
                    api('GET', 'mf_unit', 'del_detail',{id_unit:parent.id, id_detail: item.id})
                    .then((result)=>{   
                        this.props.reload();
                    });
            }
    }
    
    render(){
        return(
            <div>
                <Button size="sm" onClick={()=>this.changeCount(-1)}>-</Button>
                <input type='text' style={{width:30, backgroundColor:this.state.error?'red':'white'}}
                    onChange={(e)=>this.setState({val:e.target.value})}
                    value={this.state.val}/>
                <Button size="sm" onClick={()=>{this.changeCount(1)}}>+</Button>
                <Button size="sm" onClick={()=>{this.del()}}>Del</Button>
            </div>
        )
    }
}

class UnitItem extends Component{
    constructor(props)
    {
        super(props)
        this.state={open: false,
             add: false,
             isLoad: false,
             children: []};

             this.load();
    }
    load()
    {
        this.setState({isLoad: false,
            children: []});
        const {item} = this.props;
        api('GET', 'mf_unit', 'get_content',{id_unit:item.id})
        .then((result)=>{   
            this.setState({children:result, isLoad:true});
        });
    }
    render()
    {
        const {item, parent} = this.props;
        const {open, add, isLoad, children} = this.state;
        return(
            <Container style={{paddingRight:0, paddingTop:'15px', paddingBottom:'15px', border:'1px solid #cecece'}}>
                <Row> 
                    <Col md={1} onClick={()=>{this.setState({open:!open})}}>{open?'-':'+'}</Col>
                    <Col md={7}>{item.name}</Col>
                    <Col md={1}>
                        <Button size="sm" onClick={()=>{this.setState({add:!add})}}>
                            +
                        </Button>
                    </Col>
                    <Col md={1}>{item.count}</Col>
                    <Col md={2}>
                        <CounterEditor item={{...item, type: item.type}} parent={parent}
                         reload={this.props.reloadList}/>
                    </Col>
                </Row>
                {add&&(
                    <Row>
                        <Col md={{ span: 10, offset: 1 }}>
                            <AddBlock parent={item} reload={()=>{this.load()}}/>
                            </Col>
                    </Row>
                )}
                {(children&&open)?(
                    <Row>
                        {isLoad?(
                        <Col sm={12}><List item={children} parent={item}/></Col>):(
                            <Col><Spinner animation="grow" /></Col>
                        )}
                    </Row>) : null}
            </Container>)
            
    }    
}

class DetailItem extends Component{
    constructor(props)
    {
        super(props)
        this.state={name:'', count:0, isLoad:false};
        
        this.load();
    }
    load()
    {
        this.state={name:'', count:0, isLoad:false};

        const {item} = this.props;
        const {type, id} = this.props.parent;

        if(type==='model')
            api('GET', 'mf_models', 'get_detail',
            {id_model:id, id_detail:item})
            .then((result)=>{   
                this.setState({name:result.name, count:result.count, isLoad:true});
            });
        else if(type==='unit')
            api('GET', 'mf_unit', 'get_detail',
            {id_unit:id, id_detail:item})
            .then((result)=>{   
                this.setState({name:result.name, count:result.count, isLoad:true});
            });
    }
    render()
    {
        const {item, parent} = this.props;
        const {name, count, isLoad} = this.state;
        if(isLoad)
            return(
                <Container style={{paddingRight:0, paddingTop:'15px', paddingBottom:'15px', border:'1px solid #cecece'}}>
                    <Row> 
                        <Col md={1}></Col>
                        <Col md={8}>{name}</Col>
                        <Col md={1}>{count}</Col>
                        <Col md={2}><CounterEditor
                            reloadList={this.props.reloadList}
                            reload={()=>{this.load()}}
                            item={{id:item, type: 'detail', count:count}} parent={parent}/></Col>
                    </Row>
                </Container>)
        else
            return <Spinner animation="grow" />     
    }    
}

class List extends Component{
    constructor(props)
    {
        super(props)
        this.state={items:[], isLoad:false}

        this.load();
    }

    load()
    {
        this.state={items:[], isLoad:false}

        const {type, id} = this.props.parent;

        if(type==='model')
            api('GET', 'mf_models', 'get_content',{id_model:id})
            .then((result)=>{   
                this.setState({items:result, isLoad:true});
            });
        else if(type==='unit')
            api('GET', 'mf_unit', 'get_content',{id_unit:id})
            .then((result)=>{   
                this.setState({items:result, isLoad:true});
            });
    }

    render()
    {
        const {parent} = this.props;
        if(this.state.isLoad)
            return this.state.items.map((item,i)=>{
                if(item.type==="unit")
                    return(<UnitItem key={i} item={item}
                         reloadList={()=>{this.load()}} parent={parent}/>)
                if(item.type==="detail")
                    return(<DetailItem key={i} item={item.id}
                         reloadList={()=>{this.load()}} parent={parent}/>)
            })
        else
            return <Spinner animation="grow" />
    }
}

class AddBlock extends Component{
    constructor(props)
    {
        super(props)
        this.state={unit:{}, detail:{}, count:1};
    }

    handleChange(e) {
        if(e.target.name==="count" && e.target.value < 1)
            return 0;

        if(e.target.type=='checkbox')
            this.setState({ [e.target.id]: e.target.checked });
        else
            this.setState({ [e.target.name]: e.target.value });
    }

    AddUnit()
    {
        const {parent} = this.props
        const {unit, count} = this.state

        if(parent.type==='model')
        {
            api('GET', 'mf_models', 'add_unit',{id_model:parent.id, id_unit:unit, count: count})
            .then((result)=>{   
                this.props.reload();
            });
        }
        else if(parent.type==='unit')
        {
            api('GET', 'mf_unit', 'add_unit',{id_parent:parent.id, id_unit:unit, count: count})
            .then((result)=>{   
                this.props.reload();
            });
        }
    }

    AddDetail()
    {
        const {parent} = this.props
        const {detail, count} = this.state

        if(parent.type==='model')
        {
            api('GET', 'mf_models', 'add_detail',{id_model:parent.id, id_detail:detail, count: count})
            .then((result)=>{   
                this.props.reload();
            });
        }
        else if(parent.type==='unit')
        {
            api('GET', 'mf_unit', 'add_detail',{id_unit:parent.id, id_detail:detail, count: count})
            .then((result)=>{   
                this.props.reload();
            });
        }
    }

    render()
    {
        return(
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={2}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="first">Узел</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="second">Деталь</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={6}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Select placeholder="Узел"
                                dictionary="mf_unit" 
                                name="unit"
                                onChange={(e)=>{this.handleChange(e)}}/>
                            <Button style={{width:'100%'}} onClick={()=>{this.AddUnit()}}>
                                + Добавить
                            </Button>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Select placeholder="Деталь"
                                dictionary="wh_detail" 
                                name="detail"
                                onChange={(e)=>{this.handleChange(e)}}/>
                            <Button style={{width:'100%'}} onClick={()=>{this.AddDetail()}}>
                                + Добавить
                            </Button>
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                    <Col sm={4}>
                        <Form.Control type="number" name="count" 
                        value={this.state.count}
                        onChange={(e)=>{this.handleChange(e)}}/>
                    </Col>
                </Row>
            </Tab.Container>
        )
    }
}

class Model extends Component{
    constructor(props)
    {
        super(props);
        this.state={isLoad:true};
    }

    load()
    {

    }

    render()
    {
        const {isLoad} = this.state;
        const {model} = this.props;
        const parent={type:'model', id:model.id};

        return(
            <Container>
                <Row>
                    <Col md={5}><h2>{model.name}</h2></Col>
                    <Col md={3}><Button onClick={()=>this.props.goBack()}>Назад</Button></Col>
                </Row>
                <Row>
                    <Col>
                        {isLoad? 
                        (<div>
                            <AddBlock parent={parent} reload={()=>{this.refs.list.load()}}/>
                            <List parent={parent} ref='list'/>
                        </div>):(
                        <Spinner animation="grow" />)}
                    </Col>
                </Row>
            </Container>)
    }
}

export default Model;