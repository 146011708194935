import React, { Component } from "react"
import { connect } from "react-redux"
import {store}  from './store';
import {
    Route,
    BrowserRouter as Router,
    Switch,
    withRouter
  } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap"

import MainModule from './Modules/MainModule'
import FS from './Modules/FixService/FS'
import Tasks from './Modules/Tasks/Tasks'
import Mf from "./Modules/ManuFacture/Mf";
import IntAccounts from "./Modules/IntAccounts/IntAccounts";
import IntAccount from "./Modules/IntAccounts/IntAccount";

class ContentBlock extends Component{
    render()
    {
        return(
            <Container style={{paddingTop:'65px'}}>
                {
                    this.props.user.ID?(
                <Row>
                    <Col>
                        <Router>
                            <Switch>
                                <Route exact path='/' component={MainModule}/>
                                <Route path='/FS' component={FS}/>
                                <Route path='/Tasks' component={Tasks}/>
                                <Route path='/Mf' component={Mf}/>
                                <Route path='/IntAccounts' component={IntAccounts}/>
                                <Route path='/IntAccount/:id' component={IntAccount}/>
                            </Switch>
                        </Router>
                    </Col>
                </Row>
                    ):(<div></div>)}
            </Container>);
    }
}

const mapStateToProps = store => {
    return {
      user: store.User,
    };
  };

export default connect(
    mapStateToProps
  )(withRouter(ContentBlock));


  
