import React, { Component } from "react"
import { Container } from "react-bootstrap"

class FSOutput extends Component{
    render()
    {
        return(
        <Container>
            FSOutput
        </Container>);
    }
}

export default FSOutput;
